import * as React from "react";
import { styled } from "@mui/material/styles";

import MuiDrawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Toolbar,
  Container,
  Button,
  Stack,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GroupsIcon from "@mui/icons-material/Groups";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../images/logo.svg";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useTheme, makeStyles } from "@mui/material/styles";
import { useAuth } from "../hooks/useAuth";
import { Fingerprint } from "@mui/icons-material";
import {
  IconActivityMingle,
  IconChatMingle,
  IconEventsMingle,
  IconPlayMingle,
  IconProfileMingle,
  LogoWhatsApp,
} from "./brandlibrary/MingleIcons";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  top: "auto",
  bottom: 0,
  minHeight: "48px",
  background: "#fff",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const BottomBar: React.FC<any> = ({ children, ...props }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignContent="center"
      alignItems="center"
    >
      <Outlet />

      <AppBar
        position="fixed"
        open={true}
        color="secondary"
        sx={{ zIndex: 999, minHeight: "70px", display: "none" }}
      >
        <Toolbar
          sx={{
            pr: "20px",
            pt: "8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            color: "#000",
          }}
        >
          <div>
            <Typography variant="body1">Wedstrijdverslag maken?</Typography>
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              Neem contact op & krijg toegang tot de app.
            </Typography>
          </div>
          <a href="https://api.whatsapp.com/send?phone=31683826837">
            <LogoWhatsApp
              sx={{
                color: "#fff",
                backgroundColor: "#4dc247",
                fontSize: 48,
                borderRadius: 30,
                padding: 1,
              }}
            />
          </a>
        </Toolbar>
        <Toolbar
          sx={{
            pr: "24px",
            display: "none",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            // display: "none",
          }}
        >
          <Button
            variant="text"
            size="small"
            onClick={(e) => {
              navigate("/");
            }}
            disabled
            disableRipple
            sx={{
              flexDirection: "column",
              fontSize: "9px",

              "&:hover": {
                background: "transparent",
                textDecoration: "underline",
              },
            }}
          >
            <IconActivityMingle />
            Activity
          </Button>
          <Button
            variant="text"
            size="small"
            onClick={(e) => {
              navigate("/");
            }}
            disabled
            disableRipple
            sx={{
              flexDirection: "column",
              fontSize: "9px",

              "&:hover": {
                background: "transparent",
                textDecoration: "underline",
              },
            }}
          >
            <IconChatMingle />
            Messages
          </Button>
          <Button
            variant="text"
            size="small"
            onClick={(e) => {
              navigate("/");
            }}
            disabled
            disableRipple
            sx={{
              flexDirection: "column",
              fontSize: "9px",

              "&:hover": {
                background: "transparent",
                textDecoration: "underline",
              },
            }}
          >
            <IconPlayMingle />
            Play
          </Button>
          <Button
            variant="text"
            size="small"
            onClick={(e) => {
              navigate("/");
            }}
            disableRipple
            sx={{
              flexDirection: "column",
              fontSize: "9px",

              "&:hover": {
                background: "transparent",
                textDecoration: "underline",
              },
            }}
          >
            <IconEventsMingle />
            Events
          </Button>

          <Button
            variant="text"
            size="small"
            onClick={() => {
              navigate("/account");
            }}
            disableRipple
            sx={{
              flexDirection: "column",
              fontSize: "9px",

              "&:hover": {
                background: "transparent",
                textDecoration: "underline",
              },
            }}
          >
            <IconProfileMingle />
            Profile
          </Button>
        </Toolbar>
      </AppBar>
    </Stack>
  );
};
