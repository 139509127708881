import * as React from "react";

import { useTheme } from "@mui/material/styles";

import { TeamStats } from "../../services/api.interfaces";

import Typography from "@mui/material/Typography";
import { Avatar, Box, Stack } from "@mui/material";
import backgroundCard from "../../images/bg-picture.png";

interface Props {
  dataTeam: TeamStats;
}
export const FirstSharedPicture: React.FC<Props> = ({ dataTeam }) => {
  return (
    <>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          position: "relative",
          fontWeight: 700,
          marginBottom: "32px",
          zIndex: 5,
        }}
      >
        This was the first photo of your team
      </Typography>
      {!dataTeam?.matchesStatistics?.firstPictureSharedUrl && (
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            position: "relative",
            fontWeight: 400,
            marginBottom: "32px",
            zIndex: 5,
          }}
        >
          No team photo has been uploaded (yet).
        </Typography>
      )}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          textAlign: "center",
          fontWeight: "700",
          fontSize: "22px",
          margin: "auto",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            // width: "100%",
            // height: "100%",
            position: "absolute",
            top: 0,
            zIndex: 4,
            left: 0,
            objectFit: "cover",
            width: "350px",
            height: "622px",
            background: `transparent url(${backgroundCard}) top center no-repeat`,
          }}
        ></div>
        {dataTeam?.matchesStatistics?.firstPictureSharedUrl && (
          <img
            src={dataTeam?.matchesStatistics?.firstPictureSharedUrl}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              zIndex: 3,
              left: 0,
              objectFit: "cover",
            }}
          />
        )}
      </div>
    </>
  );
};
