import * as React from "react";

import { useTheme } from "@mui/material/styles";

import { TeamStats } from "../../services/api.interfaces";

import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";

interface Props {
  dataTeam: TeamStats;
}
export const Intro: React.FC<Props> = ({ dataTeam }) => {
  const theme = useTheme();

  return (
    <Stack mt={4} spacing={3}>
      <img
        src={
          dataTeam.logoUrl ? dataTeam.logoUrl : dataTeam.organization?.logoUrl
        }
        style={{ width: "120px", height: "auto", margin: "40px auto 0" }}
      />
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 700 }}>
        {dataTeam?.name}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {dataTeam?.nameAbbreviation}
      </Typography>
    </Stack>
  );
};
