import * as React from "react";

import { useTheme } from "@mui/material/styles";

import { TeamStats } from "../../services/api.interfaces";

import Typography from "@mui/material/Typography";
import { Avatar, Box, Stack } from "@mui/material";

interface Props {
  dataTeam: TeamStats;
}
export const FirstScore: React.FC<Props> = ({ dataTeam }) => {
  const theme = useTheme();

  const user = dataTeam?.matchesStatistics?.firstGoal;
  return (
    <>
      <Typography
        variant="body1"
        gutterBottom
        sx={{ fontWeight: 700, marginBottom: "32px" }}
      >
        The first
        <span style={{ color: "#68F3CB" }}> goal of the season</span>
        was scored by
      </Typography>
      <div
        style={{
          textAlign: "center",
          // width: "100%",
          fontWeight: "700",
          fontSize: "22px",
          margin: "auto",
        }}
      >
        <Avatar
          sx={{
            backgroundColor: "#D9D9D9",
            margin: "74px auto 22px",
            width: "162px",
            height: "162px",
          }}
          src={user?.imageUrl}
        />
        {user?.firstName}
      </div>
    </>
  );
};
