import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { AuthProvider } from "./hooks/useAuth";
import { BottomBar } from "./components/BottomBar";

import TeamReplay from "./pages/TeamReplay";
import GetMingle from "./pages/GetMingle";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<BottomBar />}>
          <Route path="*" element={<GetMingle />} />
          <Route index element={<GetMingle />} />
          <Route path=":teamId" element={<TeamReplay />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}
