import * as React from "react";

import { useTheme } from "@mui/material/styles";

import { TeamStats } from "../../services/api.interfaces";

import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import flame0 from "../../images/flame0.png";
import flame1 from "../../images/flame1.png";
import flame2 from "../../images/flame2.png";
import flame3 from "../../images/flame3.png";
import flame4 from "../../images/flame4.png";
import flame5 from "../../images/flame5.png";
import { ReactElement } from "react";
interface Props {
  dataTeam: TeamStats;
}
export const MatchRating: React.FC<Props> = ({ dataTeam }) => {
  const theme = useTheme();

  const matchRateAverage = Math.ceil(
    dataTeam?.votingResults.averageMatchRating
  );
  const imageFlame0: ReactElement = (
    <img
      src={flame0}
      style={{
        position: "relative",
        width: "310px",
        height: "auto",
        margin: "auto",
        zIndex: 1,
      }}
    />
  );
  const imageFlame1: ReactElement = (
    <img
      src={flame1}
      style={{
        position: "relative",
        width: "310px",
        height: "auto",
        margin: "auto",
        zIndex: 1,
      }}
    />
  );
  const imageFlame2: ReactElement = (
    <img
      src={flame2}
      style={{
        position: "relative",
        width: "310px",
        height: "auto",
        margin: "auto",
        zIndex: 1,
      }}
    />
  );
  const imageFlame3: ReactElement = (
    <img
      src={flame3}
      style={{
        position: "relative",
        width: "310px",
        height: "auto",
        margin: "auto",
        zIndex: 1,
      }}
    />
  );
  const imageFlame4: ReactElement = (
    <img
      src={flame4}
      style={{
        position: "relative",
        width: "310px",
        height: "auto",
        margin: "auto",
        zIndex: 1,
      }}
    />
  );
  const imageFlame5: ReactElement = (
    <img
      src={flame5}
      style={{
        position: "relative",
        width: "310px",
        height: "auto",
        margin: "auto",
        zIndex: 1,
      }}
    />
  );
  return (
    <>
      {matchRateAverage === 0 ? (
        <Typography variant="body1" gutterBottom sx={{ fontWeight: 700 }}>
          Did you know that you can rate your match after the game?
        </Typography>
      ) : (
        <Typography variant="body1" gutterBottom sx={{ fontWeight: 700 }}>
          This was the average match rating
        </Typography>
      )}

      {matchRateAverage === 0 && (
        <>
          <div
            style={{
              position: "relative",
              margin: "120px auto -120px",
              zIndex: 10,
            }}
          ></div>
          {imageFlame0}
        </>
      )}
      {matchRateAverage === 1 && (
        <>
          <div
            style={{
              position: "relative",
              margin: "120px auto -120px",
              zIndex: 10,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              Poor
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              sx={{ fontWeight: 700, color: "#ADADB8", fontSize: "10px" }}
            >
              Team performance
            </Typography>
          </div>
          {imageFlame1}
        </>
      )}
      {matchRateAverage === 2 && (
        <>
          <div
            style={{
              position: "relative",
              margin: "120px auto -120px",
              zIndex: 10,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              Average
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              sx={{ fontWeight: 700, color: "#ADADB8", fontSize: "10px" }}
            >
              Team performance
            </Typography>
          </div>
          {imageFlame2}
        </>
      )}
      {matchRateAverage === 3 && (
        <>
          <div
            style={{
              position: "relative",
              margin: "120px auto -120px",
              zIndex: 10,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              Okay
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              sx={{ fontWeight: 700, color: "#ADADB8", fontSize: "10px" }}
            >
              Team performance
            </Typography>
          </div>
          {imageFlame3}
        </>
      )}
      {matchRateAverage === 4 && (
        <>
          <div
            style={{
              position: "relative",
              margin: "120px auto -120px",
              zIndex: 10,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              Good
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              sx={{ fontWeight: 700, color: "#ADADB8", fontSize: "10px" }}
            >
              Team performance
            </Typography>
          </div>
          {imageFlame4}
        </>
      )}
      {matchRateAverage === 5 && (
        <>
          <div
            style={{
              position: "relative",
              margin: "120px auto -120px",
              zIndex: 10,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              Awesome
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              sx={{ fontWeight: 700, color: "#ADADB8", fontSize: "10px" }}
            >
              Team performance
            </Typography>
          </div>
          {imageFlame5}
        </>
      )}
      {matchRateAverage !== 0 && (
        <div>
          <Typography variant="h2" sx={{ fontWeight: 700, marginBottom: 0 }}>
            {matchRateAverage}
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            sx={{ fontWeight: 400, fontSize: "10px" }}
          >
            from 5
          </Typography>
        </div>
      )}
    </>
  );
};
