import * as React from "react";

import { styled, useTheme } from "@mui/material/styles";

import { matchStats, TeamStats, user } from "../../services/api.interfaces";

import Typography from "@mui/material/Typography";
import {
  Table,
  Avatar,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  Stack,
  Chip,
} from "@mui/material";
import leaderBoardsBackground from "../../images/leaderboards-header-bg.png";
import { ReactElement } from "react";

interface Props {
  dataMatch: matchStats;
  title: ReactElement;
  showViewsCount?: boolean;
}

export const MatchTop: React.FC<Props> = ({
  dataMatch,
  title,
  showViewsCount = false,
}) => {
  const theme = useTheme();

  return (
    <>
      {title}
      {dataMatch ? (
        <>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            sx={{ margin: "20px 0 40px" }}
            spacing={3}
          >
            <Stack spacing={1} sx={{ width: "33%" }}>
              <img
                src={dataMatch?.team?.organization?.logoUrl}
                style={{ width: "40px", height: "auto", margin: "10px auto 0" }}
              />
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, fontSize: "12px", marginBottom: "2px" }}
              >
                {dataMatch?.team?.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 700, fontSize: "10px" }}
              >
                {dataMatch?.team?.shortName}
              </Typography>
            </Stack>
            <div style={{ width: "33%" }}>
              <Chip
                label={
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: 700,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 8px",
                    }}
                  >
                    {dataMatch?.score?.team}{" "}
                    <span
                      style={{
                        color: "#E6E6EA",
                        fontWeight: 300,
                        fontSize: "10px",
                        padding: "0 4px",
                      }}
                    >
                      |
                    </span>{" "}
                    {dataMatch?.score?.opponent}
                  </Typography>
                }
                sx={{ backgroundColor: "#fff", padding: "0 8px" }}
              />
            </div>
            <Stack spacing={1} sx={{ width: "33%" }}>
              <img
                src={dataMatch?.opponent?.organization?.logoUrl}
                style={{ width: "40px", height: "auto", margin: "10px auto 0" }}
              />
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, fontSize: "12px", marginBottom: "2px" }}
              >
                {dataMatch?.opponent?.name}
              </Typography>
            </Stack>
          </Stack>
          {showViewsCount && (
            <Stack
              justifyContent="space-between"
              alignContent="center"
              alignItems="center"
              direction={"row"}
              sx={{
                borderRadius: "14px",
                marginTop: "20px",
                fontWeight: 700,
                padding: "8px 16px 0 16px",

                background: "rgba(255,255,255, .19)",
              }}
            >
              <Typography variant="body2" gutterBottom sx={{ fontWeight: 400 }}>
                Total views
              </Typography>{" "}
              <Typography variant="h3" gutterBottom sx={{ fontWeight: 700 }}>
                {dataMatch?.viewCount}
              </Typography>
            </Stack>
          )}
          {dataMatch?.goals?.length > 0 && (
            <div
              style={{
                borderRadius: "14px",
                marginTop: "20px",
                color: "#fff",
                fontWeight: 700,
                fontSize: "12px",
                padding: "8px 16px",
                textAlign: "left",
                paddingBottom: `12px`,
                borderBottom: "0",
                background: "rgba(255,255,255, .19)",
              }}
            >
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignContent="center"
                alignItems="center"
                flexWrap={"wrap"}
                spacing={1}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    width: "100%",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  Goals by:{" "}
                </Typography>
                {dataMatch.goals.map((goal) => {
                  if (goal.firstName === null) {
                    return null;
                  } else
                    return (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 600, width: "40%" }}
                        // sx={{ borderBottom: "1px solid gray" }}
                      >
                        {" "}
                        {goal.firstName}
                      </Typography>
                    );
                })}
              </Stack>
            </div>
          )}
        </>
      ) : (
        <>
          <Typography variant="body1" gutterBottom sx={{ fontWeight: 400 }}>
            Unfortunately, there is not enough data available to show the
            competition data.
          </Typography>
        </>
      )}
    </>
  );
};
