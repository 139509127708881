import * as React from "react";

import { useTheme } from "@mui/material/styles";

import { TeamStats } from "../../services/api.interfaces";

import Typography from "@mui/material/Typography";
import { Avatar, Box, Stack } from "@mui/material";

interface Props {
  dataTeam: TeamStats;
}
export const Players: React.FC<Props> = ({ dataTeam }) => {
  const theme = useTheme();

  return (
    <>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          fontWeight: 700,
          marginBottom: dataTeam.players?.length > 30 ? "4px" : "32px",
          marginTop: dataTeam.players?.length > 30 ? "4px" : "32px",
        }}
      >
        The players from {dataTeam.name}
      </Typography>

      <Stack
        mt={dataTeam.players?.length > 30 ? 0 : 4}
        spacing={0}
        direction="row"
        flexWrap="wrap"
        style={{ height: "420px", overflowY: "auto" }}
      >
        {dataTeam.players.map((user, index) => {
          return (
            <div
              key={user.id + index}
              style={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "10px",
                margin: "7px",
                display: "inline-block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "35px",
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: "#D9D9D9",
                  margin: "4px auto",
                  width: "32px",
                  height: "32px",
                }}
                src={user?.imageUrl}
              />
              {user?.firstName}
            </div>
          );
        })}
      </Stack>
    </>
  );
};
