import * as React from "react";

import { useTheme } from "@mui/material/styles";

import { TeamStats } from "../../services/api.interfaces";

import Typography from "@mui/material/Typography";
import { Avatar, Box, Stack } from "@mui/material";
import backgroundCard from "../../images/bg-picture.png";

interface Props {
  dataTeam: TeamStats;
}
export const MostPopularPictures: React.FC<Props> = ({ dataTeam }) => {
  const theme = useTheme();

  const user = dataTeam?.matchesStatistics?.firstGoal;
  return (
    <>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          position: "relative",
          fontWeight: 700,
          marginBottom: "32px",
          zIndex: 5,
        }}
      >
        These were the team's most popular photos
      </Typography>
      {dataTeam?.matchesStatistics?.mostCheersPictureUrls?.length === 0 && (
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            position: "relative",
            fontWeight: 400,
            marginBottom: "32px",
            zIndex: 5,
          }}
        >
          No team photo has been uploaded (yet).
        </Typography>
      )}

      {dataTeam?.matchesStatistics?.mostCheersPictureUrls[0] && (
        <img
          src={dataTeam?.matchesStatistics?.mostCheersPictureUrls[0]}
          style={{
            width: "100%",
            height: "190px",
            position: "relative",
            borderRadius: "8px",
            objectFit: "cover",
          }}
        />
      )}
      <Stack
        direction={
          dataTeam?.matchesStatistics?.mostCheersPictureUrls[2]
            ? "row"
            : "column"
        }
        flexWrap="nowrap"
        spacing={3}
        style={{ marginTop: "8px" }}
      >
        {dataTeam?.matchesStatistics?.mostCheersPictureUrls[1] && (
          <div
            style={{
              display: "block",
              width: "100%",
              height: "200px",
              position: "relative",
              borderRadius: "8px",
              objectFit: "cover",
              background: "red",
            }}
          >
            <img
              src={dataTeam?.matchesStatistics?.mostCheersPictureUrls[1]}
              style={{
                width: "100%",
                height: "200px",
                position: "relative",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
          </div>
        )}
        {dataTeam?.matchesStatistics?.mostCheersPictureUrls[2] && (
          <div
            style={{
              width: "100%",

              display: "block",
              height: "200px",
              position: "relative",
              borderRadius: "8px",
              objectFit: "cover",
              background: "red",
            }}
          >
            {" "}
            <img
              src={dataTeam?.matchesStatistics?.mostCheersPictureUrls[2]}
              style={{
                width: "100%",
                height: "200px",
                position: "relative",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
          </div>
        )}
      </Stack>
    </>
  );
};
