import {
  baseWebApiUrl,
  ForgotPasswordCredentials,
  LoginAnonymousCredentials,
  LoginResponseInterface,
  ResetPasswordCredentials,
  SignInCredentials,
  SignUpCredentials,
  storage,
} from "./api.interfaces";
import axios from "axios";
import { useAuth } from "../hooks/useAuth";
import axiosinterceptor from "./api.interceptor";

export const fetchJoinCommentFeed = (
  id: string,
  user: LoginResponseInterface
): Promise<any> => {
  return axiosinterceptor({
    method: "POST",
    url: `/MatchFeed/JoinCommentFeed`,

    data: {
      matchFeedId: storage.getMatchId(),
      messageId: id,
    },
  }).then((res) => {
    return res.data;
  });
};

export const fetchJoinMatchFeed = (
  user: LoginResponseInterface
): Promise<any> => {
  return axiosinterceptor({
    method: "GET",
    url: `/MatchFeed/JoinMatchFeed/${storage.getMatchId()}`,
    data: {
      matchFeedId: storage.getMatchId(),
    },
  }).then((res) => {
    return res.data;
  });
};

export const fetchLeaveMatchFeed = (
  user: LoginResponseInterface
): Promise<any> => {
  return axiosinterceptor({
    method: "POST",
    url: `/matchFeed/LeaveFeed`,
    data: {
      groupId: storage.getMatchId(),
    },
  }).then((res) => {
    return res.data;
  });
};
export const postResetPassword = (
  params: ResetPasswordCredentials
): Promise<any> => {
  return axiosinterceptor({
    method: "POST",
    url: `/User/ResetPassword`,
    data: {
      changePasswordId: params.changePasswordId,
      newPassword: params.newPassword,
    },
  }).then<any>((res) => res.data);
};
export const postForgotPassword = (
  params: ForgotPasswordCredentials
): Promise<any> => {
  return axiosinterceptor({
    method: "POST",
    url: `/User/ForgotPassword`,
    data: {
      username: params.username,
    },
  }).then<any>((res) => res.data);
};
export const postSignUp = (
  params: SignUpCredentials
): Promise<LoginResponseInterface> => {
  return axiosinterceptor({
    method: "POST",
    url: `/User/RegisterUser`,
    headers: { accept: "*/*" },
    data: {
      firstName: params.firstName,
      lastName: params.lastName,
      password: params.password,
      email: params.email,
    },
  }).then<LoginResponseInterface>((res) => res.data);
};

export const postSignIn = (
  params: SignInCredentials
): Promise<LoginResponseInterface> => {
  return axiosinterceptor({
    method: "POST",
    url: `/User/GetAccessToken`,
    data: { userName: params.email, password: params.password },
  })
    .then<LoginResponseInterface>((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};

export const postVerify = (
  verificationId: string | undefined
): Promise<any> => {
  return axiosinterceptor({
    method: "POST",
    url: `/User/VerifyEmail`,
    data: { verificationId: verificationId },
  })
    .then<any>((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};
export const getUserInvite = (inviteId: string | undefined): Promise<any> => {
  return axiosinterceptor({
    method: "GET",
    url: `/User/follower/invite/page`,
    params: {
      encryptedrequestinguser: inviteId,
    },
  })
    .then<any>((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};
export const postAcceptFollowInvite = (
  EncryptedRequestingUser: string | undefined
): Promise<any> => {
  return axiosinterceptor({
    method: "POST",
    url: `/User/follower/invite/accept`,
    data: { EncryptedRequestingUser },
  })
    .then<any>((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};
export const postUserFollowInvite = (
  userId: string | undefined
): Promise<any> => {
  return axiosinterceptor({
    method: "POST",
    url: `/User/follow`,
    data: { userId },
  })
    .then<any>((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};
export const createAnonymousUser = (
  params: LoginAnonymousCredentials
): Promise<LoginResponseInterface> => {
  return axiosinterceptor({
    method: "POST",
    url: `/User/CreateUser`,
    data: { firstName: params.firstName, lastName: params.lastName },
  }).then<LoginResponseInterface>((res) => res.data);
};
