import axios from "axios";
import React, { ReactElement } from "react";

export type LocationProps = {
  state: {
    from: Location;
  };
  pathname: string;
};

export interface CardShare {
  cardType: string;
  showMainTitleImage: boolean;
  showBottomBadges: boolean;
  showCard?: boolean;
  title: ReactElement;
  ref: any;
}
export const baseWebApiUrl: string | undefined =
  process.env.REACT_APP_BASE_WEBAPPI_URL;
export interface ResetPasswordCredentials {
  changePasswordId: string;
  newPassword: string;
}
export interface ForgotPasswordCredentials {
  username: string;
}
export interface IreceivingUser {
  firstName: string;
  imageUrl: string;
  lastName: string;
  id: string;
}
export enum PrivacyEnum {
  Private = "Private",
  Public = "Public",
  Followers = "Followers",
}
export interface IrequestingUser {
  firstName: string;
  imageUrl: string;
  lastName: string;
  privacy: string;
  teams: TeamInterface[];
}

export interface SignInCredentials {
  email: string;
  password: string;
}
export interface SignUpCredentials {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirm_terms_privacy?: boolean;
}
export interface LoginAnonymousCredentials {
  firstName: string;
  lastName: string;
}
export interface LoginResponseInterface {
  userId: string;
  token: string;
  refreshToken: string;
  tokenExpirationDate: string;
}
export const emptyUser: LoginResponseInterface = {
  userId: "",
  token: "",
  refreshToken: "",
  tokenExpirationDate: "",
};
export interface AuthContextType {
  user: LoginResponseInterface;
  signIn: (params: SignInCredentials) => Promise<any>;
  signUp: (params: SignUpCredentials) => Promise<any>;
  signOut: () => any;
  resetPassword: (params: ResetPasswordCredentials) => Promise<any>;
  forgotPassword: (params: ForgotPasswordCredentials) => Promise<any>;
}

export const storage = {
  getUser: () =>
    JSON.parse(
      window.localStorage.getItem("user") || JSON.stringify(emptyUser)
    ),
  setUser: (user: LoginResponseInterface) =>
    window.localStorage.setItem("user", JSON.stringify(user)),
  clearUser: () => window.localStorage.removeItem("user"),
  getRefreshToken: () => {
    return storage.getUser()?.refreshToken;
  },
  getAccessToken: () => {
    return storage.getUser()?.token;
  },
  updateAccessToken: (token: string) => {
    let user = storage.getUser();
    user.token = token;
    localStorage.setItem("user", JSON.stringify(user));
  },
  getMatchId: () => {
    return JSON.parse(
      window.localStorage.getItem("matchFeedId") || JSON.stringify("")
    );
  },
  setMatchId: (id: string) => {
    localStorage.setItem("matchFeedId", JSON.stringify(id));
  },
};
export interface FeedInputEvents {
  messageToUncheerId?: string;
  messageToCheerId?: string;
  comment?: string;
  messageId?: string;
}

export enum FeedCardTypesEnum {
  GOAL = "GOAL",
  IMAGE = "IMAGE",
  MATCH_ENDED = "MATCH_ENDED",
  TEAM = "TEAM",
  MATCH_STARTED = "MATCH_STARTED",
  MATCH_HALFTIME = "MATCH_HALFTIME",
  MATCH_SECONDHALF = "MATCH_SECONDHALF",
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
  TEXT = "TEXT",
}

export enum ConnectionStatusEnum {
  pending = "pending",
  active = "active",
  inactive = "inactive",
}
export enum StatusEnum {
  Scheduled = "Scheduled",
  Started = "Started",
  SecondHalf = "SecondHalf",
  HalfTime = "HalfTime",
  Finished = "Finished",
}
export enum MessageTypeEnum {
  DELETE_MESSAGE_CHEER = "DELETE_MESSAGE_CHEER",
  MESSAGE_CHEER = "MESSAGE_CHEER",
  COMMENT = "COMMENT",
}
export interface WssInputEvents {
  type: string;
  event: string;
  group: string;
  dataType: string;
  data: {
    id: string;
    actionType: string;
    feedVersion: number;
    type: string;
    matchFeedId: string;
    data: {};
  };
}
export interface matchStats {
  goals: user[];
  id: string;
  location: string;
  opponent: TeamInterface;
  scheduledAtUtc: string;
  score: { team: number; opponent: number };
  team: TeamInterface;
  viewCount: number;
}
export interface TeamStats {
  matchesStatistics: {
    played: number;
    won: number;
    lost: number;
    draw: number;
    totalCheers: number;
    totalComments: number;
    totalViews: number;
    totalGoals: number;
    averageGoalsPerMatch: number;
    firstPictureSharedUrl: string;
    mostCheersPictureUrls: string[];
    mostViewedMatch: matchStats;
    biggestWinMatch: matchStats;
    firstGoal: {
      id: string;
      imageUrl: string;
      firstName: string;
      lastName: string;
      displayName: string;
    };
  };
  leaderboards: {
    goals: user[];
    assists: user[];
  };
  id: string;
  name: string;
  logoUrl: string;
  nameAbbreviation: string;
  fullName: string;
  shortName: string;
  club: {
    id: string;
    name: string;
    shortName: string;
    logoUrl: string;
  };
  organization: {
    id: string;
    isVerified: boolean;
    logoUrl: string;
    name: string;
  };
  players: user[];
  trainers: [
    {
      id: string;
      imageUrl: string;
      firstName: string;
      lastName: string;
      displayName: string;
    }
  ];

  votingResults: {
    mostVotedUsers: [
      {
        type: string;
        users: votedUser[];
      },
      {
        type: string;
        users: votedUser[];
      },
      {
        type: string;
        users: votedUser[];
      }
    ];
    averageMatchRating: number;
  };
}
export interface votedUser {
  voteCount: string;
  user: user;
}
export interface user {
  displayName: string;
  firstName: string;
  id: string;
  imageUrl: string;
  lastName: string;
  metricValue: number;
  position: number;
}
export interface MatchFeedHistoryInterface {
  id: string;
  sender: {
    id: string;
    displayName: string;
    profileImageUrl: string;
  };
  type: string;
  actionType: string;
  sentAtUtc: string;
  reactions: {
    cheers: string[];
    comments?: number;
    isUpdating?: boolean;
  };
  feedVersion: number;
  data: {
    teamId?: string;
    playerId?: string;
    assistantId?: string;
    score?: {
      team: number;
      opponent: number;
    };
    players?: PlayersInterface[];
    player?: {
      id: string;
      displayName: string;
      imageUrl: string;
    };
    assistant?: {
      id: string;
      displayName: string;
      imageUrl: string;
    };
    audioUrl?: string | undefined;
    videoUrl?: string;
    thumbnailUrl?: string;
    imageUrl?: string;
    text?: string;
    description?: {
      text?: string;
    };
  };
}

export interface PlayersInterface {
  id: string;
  firstName: string;
  lastName: string;
  imageUrl: string | undefined;
}
export interface PlayerInterface {
  id: string;
  displayName: string;
  profileImageUrl: string | null;
}
export interface TrainerInterface {
  id: string;
  displayName: string;
  profileImageUrl: string | null;
}
export interface ClubInterface {
  id: string;
  name: string;
  shortName: string;
  logoUrl: string | undefined;
}
export interface IOrganization {
  id: string;
  name: string;
  logoUrl: string;
  isVerified: boolean;
}
export interface TeamInterface {
  id: string;
  name: string;
  fullName: string;
  shortName: string;
  logoUrl?: string | null;
  club: ClubInterface;
  players?: PlayerInterface[] | null;
  trainers?: TrainerInterface[] | null;
  type?: string;
  organization?: IOrganization;
}
export interface StatusUpdatesInterface {
  status: StatusEnum;
  updatedAtUtc: string;
}

export interface MatchStatusInterface {
  actionType: string;
  data: {
    endedAtUtc: string;
    players: string[];
    scheduledAtUtc: string;
    score: {
      team: number;
      opponent: number;
    };
    startedAtUtc: string;
    status: {
      name: StatusEnum;
      value: number;
    };
    views: number;
    statusUpdates: StatusUpdatesInterface[];
  };
  feedVersion: number;
  id: string;
  reactions: {
    cheers: string[];
    comments?: number;
    isUpdating?: boolean;
  };
  sender: {
    displayName: string;
    id: string;
    profileImageUrl: string;
  };
  sentAtUtc: string;
  type: string;
}
export enum HomeAwayEnum {
  Home = "Home",
  Away = "Away",
}
export interface MatchFeedsInterface {
  id: string;
  webSocketUrl: string;
  isReporter: boolean;
  viewsCount: number;
  match: {
    id: string;
    location: HomeAwayEnum;
    team: TeamInterface;
    opponent: TeamInterface;
    scheduledAtUtc: string;
    isReported: boolean;
    startedAtUtc: string;
    endedAtUtc: string;
    hasGoals: boolean;
    views: number;
    score: {
      team: number;
      opponent: number;
    };
    status: StatusEnum;
    statusUpdates: StatusUpdatesInterface[];
  };
  feedVersion: number;
  fileContainerId: string;
}
export interface CommentFeedInterface {
  id: string;
  sender: {
    id: string;
    displayName: string;
    profileImageUrl: string;
  };
  type: string;
  actionType: string;
  sentAtUtc: string;
  reactions: {
    cheers: [];
    comments: number;
  };
  feedVersion: number;
  data: {
    messageId: string;
    comment: string;
  };
}
