import * as React from "react";

import { styled, useTheme } from "@mui/material/styles";

import { TeamStats, user, votedUser } from "../../services/api.interfaces";

import Typography from "@mui/material/Typography";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
} from "@mui/material";
import mostVotedmvp from "../../images/mostvoted-mvp-3.png";
import mostVotedcreative from "../../images/mostvoted-creative-3.png";
import mostVotedeffort from "../../images/mostvoted-effort-3.png";
import { ReactElement } from "react";

interface Props {
  users?: votedUser[] | null;
  title: ReactElement;
  teamName: string;
  type: string;
}

export const MostVotedUser: React.FC<Props> = ({
  users,
  title,
  teamName,
  type,
}) => {
  const theme = useTheme();

  const StyledTableBackgroundMVP = styled("div")(({ theme }) => ({
    position: "relative",
    height: "200px",
    width: "87%",
    background: `url(${mostVotedmvp}) center center`,
    backgroundSize: "100% 100%",
    margin: "30px auto",
  }));

  const StyledTableBackgroundEffort = styled("div")(({ theme }) => ({
    position: "relative",
    height: "200px",
    width: "87%",
    background: `url(${mostVotedeffort}) center center`,
    backgroundSize: "100% 100%",
    margin: "30px auto",
  }));
  const StyledTableBackgroundCreative = styled("div")(({ theme }) => ({
    position: "relative",
    height: "200px",
    width: "87%",
    background: `url(${mostVotedcreative}) center center`,
    backgroundSize: "100% 100%",
    margin: "30px auto",
  }));

  return (
    <>
      {title}
      <div
        style={{
          position: "relative",
          width: "350px",
          height: "542px",
          margin: "80px auto",
        }}
      >
        {users?.map((user, index) => {
          return (
            <div key={user.user.id + type}>
              <Typography
                variant="h6"
                sx={{
                  position: "absolute",
                  top: index === 0 ? "10px" : "31px",
                  left: index === 0 ? "135px" : index === 1 ? "36px" : "233px",
                  zIndex: 10,
                  fontWeight: 700,
                  fontSize: "12px",
                  color:
                    type === "mvp"
                      ? "#FFBF29"
                      : type === "playmaker"
                      ? "#E7E9FA"
                      : "#C3FAEA",
                }}
              >
                {user.user.firstName}
              </Typography>
              <Avatar
                sx={{
                  position: "absolute",
                  top: index === 0 ? "38px" : "59px",
                  left: index === 0 ? "146px" : index === 1 ? "47px" : "244px",
                  backgroundColor: "#D9D9D9",
                  marginRight: "6px",
                  width: "59px",
                  height: "59px",
                }}
                src={user?.user.imageUrl}
              />
              <Typography
                variant="h6"
                sx={{
                  position: "absolute",
                  top: index === 0 ? "114px" : "135px",
                  left: index === 0 ? "135px" : index === 1 ? "36px" : "233px",
                  zIndex: 10,
                  fontWeight: 700,
                  fontSize: "11px",
                  textAlign: "center",
                  width: "80px",
                  color:
                    type === "mvp"
                      ? "#FFBF29"
                      : type === "playmaker"
                      ? "#E7E9FA"
                      : "#C3FAEA",
                }}
              >
                [{user.voteCount} votes]
              </Typography>
            </div>
          );
        })}
        {type === "mvp" && <StyledTableBackgroundMVP />}
        {type === "workhorse" && <StyledTableBackgroundEffort />}
        {type === "playmaker" && <StyledTableBackgroundCreative />}
      </div>
    </>
  );
};
