import * as React from "react";

import { styled, useTheme } from "@mui/material/styles";

import { TeamStats, user } from "../../services/api.interfaces";

import Typography from "@mui/material/Typography";
import {
  Table,
  Avatar,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
} from "@mui/material";
import leaderBoardsBackground from "../../images/leaderboards-header-bg.png";
import { ReactElement } from "react";

interface Props {
  dataLeaderBoard: user[];
  title: ReactElement;
}
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  position: "relative",
  color: "#fff",
  fontWeight: 700,
  fontSize: "12px",
  padding: "8px 16px",
  textAlign: "left",
  marginBottom: `2px`,
  borderBottom: "0",
  background: "rgba(255,255,255, .25)",
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({}));
export const LeaderBoard: React.FC<Props> = ({ dataLeaderBoard, title }) => {
  const theme = useTheme();

  return (
    <>
      {title}
      {dataLeaderBoard?.length > 0 ? (
        <Table
          sx={{
            marginTop: "70px",
            borderSpacing: "0 2px",
            borderCollapse: "separate",
          }}
        >
          <TableBody>
            <StyledTableRow sx={{ height: "80px" }}>
              <StyledTableCell colSpan={3}>
                <div
                  style={{
                    position: "absolute",
                    bottom: "4px",
                    left: "4px",
                    minHeight: "140px",
                    width: "calc(100% - 8px)",
                    background: `url(${leaderBoardsBackground}) center center`,
                    backgroundSize: "100% 100%",
                  }}
                >
                  {dataLeaderBoard[0] && (
                    <>
                      <Typography
                        variant="body2"
                        sx={{
                          position: "absolute",
                          top: "7px",
                          left: "106px",
                          color: "#000",
                          fontSize: "12px",
                          fontWeight: 700,
                        }}
                      >
                        {dataLeaderBoard[0].firstName}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          position: "absolute",
                          top: "87px",
                          left: "147px",
                          color: "#000",
                          fontSize: "11px",
                          fontWeight: 700,
                        }}
                      >
                        {dataLeaderBoard[0].metricValue}
                      </Typography>
                      <Avatar
                        sx={{
                          position: "absolute",
                          top: "31px",
                          left: "125px",
                          backgroundColor: "#D9D9D9",
                          marginRight: "6px",
                          width: "52px",
                          height: "52px",
                        }}
                        imgProps={{
                          width: "52",
                          height: "20",
                          sx: { width: "52px" },
                        }}
                        src={dataLeaderBoard[0]?.imageUrl}
                      />
                    </>
                  )}
                  {dataLeaderBoard[1] && (
                    <>
                      <Typography
                        variant="body2"
                        sx={{
                          position: "absolute",
                          top: "32px",
                          left: "9px",
                          color: "#000",
                          fontSize: "10px",
                          fontWeight: 700,
                        }}
                      >
                        {dataLeaderBoard[1]?.firstName}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          position: "absolute",
                          top: "94px",
                          left: "44px",
                          color: "#000",
                          fontSize: "10px",
                          fontWeight: 700,
                        }}
                      >
                        {dataLeaderBoard[1]?.metricValue}
                      </Typography>
                      <Avatar
                        sx={{
                          position: "absolute",
                          top: "52px",
                          left: "25px",
                          backgroundColor: "#D9D9D9",
                          marginRight: "6px",
                          width: "40px",
                          // height: "auto",
                        }}
                        src={dataLeaderBoard[1]?.imageUrl}
                      />
                    </>
                  )}
                  {dataLeaderBoard[2] && (
                    <>
                      <Typography
                        variant="body2"
                        sx={{
                          position: "absolute",
                          top: "32px",
                          left: "221px",
                          color: "#000",
                          fontSize: "10px",
                          fontWeight: 700,
                        }}
                      >
                        {dataLeaderBoard[2]?.firstName}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          position: "absolute",
                          top: "94px",
                          left: "253px",
                          color: "#000",
                          fontSize: "10px",
                          fontWeight: 700,
                        }}
                      >
                        {dataLeaderBoard[2]?.metricValue}
                      </Typography>
                      <Avatar
                        sx={{
                          position: "absolute",
                          top: "52px",
                          left: "236px",
                          backgroundColor: "#D9D9D9",
                          marginRight: "6px",
                          width: "40px",
                          // height: "auto",
                        }}
                        src={dataLeaderBoard[2]?.imageUrl}
                      />
                    </>
                  )}
                </div>
              </StyledTableCell>
            </StyledTableRow>
            {dataLeaderBoard.map((user, index) => {
              if (index > 2 && index < 10) {
                return (
                  <StyledTableRow key={user?.id}>
                    <StyledTableCell width={30}>
                      <Typography variant="h5" sx={{ fontWeight: "700" }}>
                        {index + 1}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>{user?.firstName}</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right" }}>
                      {user?.metricValue}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      ) : (
        <>
          <Typography variant="body1" gutterBottom sx={{ fontWeight: 400 }}>
            Unfortunately, there is not enough data available to show the
            leaderboard.
          </Typography>
        </>
      )}
    </>
  );
};
