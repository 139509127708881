import axios from "axios";

import { LocationProps, storage } from "./api.interfaces";
import { useLocation } from "react-router-dom";

const axiosinterceptor = axios.create({
  baseURL: process.env.REACT_APP_BASE_WEBAPPI_URL,
});
axiosinterceptor.interceptors.request.use(
  (config) => {
    const token = storage.getAccessToken();
    if (token && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosinterceptor.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/User/GetAccessToken" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await axios.post(
            "/User/RefreshAccessToken",
            {
              refreshAccessTokenRequest: storage.getRefreshToken(),
            },
            {
              headers: { Authorization: `Bearer ${storage.getAccessToken()}` },
            }
          );
          const { accessToken } = rs.data;
          storage.updateAccessToken(accessToken);
          return axios(originalConfig);
        } catch (_error) {
          window.location.href = `/logout?from=${window.location.pathname}`;
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);
export default axiosinterceptor;
